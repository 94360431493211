import * as yup from 'yup';
import moment from 'moment';
import GenericField from '@/shared/fields/generic-field';
import { validators } from '@/shared/form/validators';
import { storeAsync } from "@/app-module";

export default class DateField extends GenericField {
    constructor(name, label, { required = false, dateFormatType='' } = {}, filterParams = {}) {
        super(name, label, filterParams);

        this.required = required;
        this.dateFormatType = dateFormatType;
        this.type = 'daterange';
        this.dateFormat = storeAsync().getters['shared/appConfig']?.DATE_FORMAT || 'DD-MM-YYYY';
    }

    forPresenter(value) {
        return value ? moment(value).format(this.dateFormat) : null;
    }

    forFilterCast() {

        return yup
            .mixed()
            .nullable(true)
            .label(this.label)
            .transform((value) =>
                value ? moment(value).format(this.dateFormat) : null,
            );
    }

    forFormRules() {
        let output = {};
        if (this.required) {
            output = {
                ...output,
                ...validators.required(),
            };
        }
        if (this.dateFormatType) {
            output = {
                ...output,
                ...validators.date(this.dateFormatType),
            };
        }
        return output;
    }

    forFilterRules() {
        return undefined;
    }

    forFormInitialValue(value) {
        return value;
    }

    forFormCast() {
        let yupChain = yup
            .mixed()
            .nullable(true)
            .label(this.label);

        return yupChain;
    }
}
