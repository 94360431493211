import KTUtil from '@/assets/js/components/util';
import formValidation from '@/assets/plugins/formvalidation/dist/es6/core/Core';
import Trigger from '@/assets/plugins/formvalidation/dist/es6/plugins/Trigger';
import Bootstrap from '@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap';
import Excluded from '@/assets/plugins/formvalidation/dist/es6/plugins/Excluded';

export const createFormValidation = (formName, rules, excludedList = []) => {
    const form = KTUtil.getById(formName);

    return formValidation(form, {
        fields: rules,
        plugins: {
            trigger: new Trigger(),
            bootstrap: new Bootstrap(),
            excluded: new Excluded({
                excluded: function(field, element) {
                    // Exclude fields from excludedList and disabled
                    return excludedList?.some((el) => el === field && element.disabled);
                }
            }),
        }
    });
}
