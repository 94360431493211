import GenericModel from '@/shared/models/generic-model';
import i18n from '@/shared/plugins/vue-i18n';
import StringField from '@/shared/fields/string-field';
import IdField from '@/shared/fields/id-field';
import EnumField from '@/shared/fields/enum-field';
import DateField from "@/shared/fields/date-field";

const label = (name) => i18n.t(`DOCUMENT_TEMPLATE.FIELDS.${name.toUpperCase()}`);
const enumLabel = (name, value) => i18n.t(`DOCUMENT_TEMPLATE.ENUMS.${name.toUpperCase()}.${value.toUpperCase()}`);
const placeholder = (name) => i18n.t(`DOCUMENT_TEMPLATE.PLACEHOLDERS.${name.toUpperCase()}`);

const fields = {
    id: new IdField('id', 'Id'),
    name: new StringField('name', label('name'), { required: true }, {
        placeholder: placeholder('name'),
    }), 
    description: new StringField('description', label('description'), { required: true }, {
        placeholder: placeholder('description'),
    }), 
    status: new EnumField(
        'status',
        label('status'),
        [
            {
                value: 'active',
                name: 'active',
                text: enumLabel('status', 'active'),
                type: 'primary',
            },
            {
                value: 'inactive',
                name: 'inactive',
                text: enumLabel('status', 'inactive'),
                type: 'warning',
            },
        ],
        {},
        {
            placeholder: placeholder('status'),
        }
    ),
    created_date: new DateField('created_date', label('created_date')),
    type: new StringField('type', label('type'), { required: true }, {
        placeholder: placeholder('type'),
    }), 
};

export class DocumentTemplateModel extends GenericModel {
    static get fields() {
        return fields;
    }
}
